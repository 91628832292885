var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sub-head"
  }, [_c('div', {
    staticClass: "sub-visual"
  }, [_c('v-container', [_c('h2', {
    staticClass: "sub-visual__tit"
  }, [_vm._v(_vm._s(_vm.sh))])]), _c('div', {
    staticClass: "sub-visual__bg",
    style: 'background-image:url(' + _vm.bg + ');'
  })], 1), _c('client-gnb', {
    attrs: {
      "className": "sub-tab",
      "tabActive": _vm.tabActive,
      "sh": _vm.sh
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }