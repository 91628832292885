<template>
    <section class="page-section">
        <v-container v-if="this.$slots['default']">
            <slot />
        </v-container>
        <slot v-if="this.$slots['pageSectionImmersive']" name="pageSectionImmersive" />
    </section>
</template>

<script>
export default {
    props: {},
    components: {},
    data: () => {
        return {};
    },
    created() {},
    mounted() {
        this.init();
    },
    methods: {
        init: function() {},
    },
};
</script>

<style lang="scss" scoped>
.page-section {
    position: relative;
    padding: 60px 0;
    &--xxs {
        padding: 20px 0;
    }
    &--xs {
        padding: 30px 0;
    }
    &--sm {
        padding: 40px 0;
    }
    &--first {
        margin-top: -60px;
        padding-top: 60px;
    }
    &--last {
        margin-bottom: -60px;
        padding-bottom: 60px;
    }
    &__bg {
        position: absolute;
        top: 0;
        left: 50%;
        -webkit-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
        z-index: -1;
        display: block;
        width: 100vw;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .page-section {
        padding: 80px 0;
        &--xxs {
            padding: 30px 0;
        }
        &--xs {
            padding: 40px 0;
        }
        &--sm {
            padding: 60px 0;
        }
        &--first {
            margin-top: -80px;
            padding-top: 80px;
        }
        &--last {
            margin-bottom: -80px;
            padding-bottom: 80px;
        }
    }
}
@media (min-width: 1024px) {
    .page-section {
        padding: 120px 0;
        &--xxs {
            padding: 40px 0;
        }
        &--xs {
            padding: 60px 0;
        }
        &--sm {
            padding: 80px 0;
        }
        &--first {
            margin-top: -160px;
            padding-top: 160px;
        }
        &--last {
            margin-bottom: -160px;
            padding-bottom: 160px;
        }
    }
}
@media (min-width: 1200px) {
}
</style>
